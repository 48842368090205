.header-home {
  background: url("../assets/skydive_2304x1032.webp") center no-repeat;
  padding: 2rem 1rem;
  margin-bottom: 2rem;
  background-color: #e9ecef;
  border-radius: 0.3rem;
  text-align: center;
}

.banner-text.text-center {
  padding: 18rem 0 22rem;
}

.socialicons {
  width: 100%;
  text-align: center;
}

.socialicons ul {
  margin: 2px auto;
  line-height: 1.4;
  padding-left: 0;
}

.socialicons li {
  display: inline;
  text-align: center;
}

.socialicons a {
  text-decoration: none;
  margin: 10px;
  padding: 2px 5px;
  text-align: center;
  white-space: nowrap;
}
