.overlay {
  background-color: rgba(0, 0, 0, 0);
  text-align: center;
  padding-top: 1em;
  padding-bottom: 1em;
}

.typewrite {
  color: black;
  text-decoration: none;
  font-weight: bold;
}

.instructions {
  color: black;
  text-align: left;
  text-decoration: none;
  font-weight: bold;
  padding-left: 1em;
  width: 600px;
}
